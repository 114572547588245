import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-lev/"
            }}>{`All-Stars Leveraged`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth`}</li>
        <li parentName="ul">{`Type: meta-portfolio`}</li>
        <li parentName="ul">{`Invests in: individual stocks, ETFs tracking stocks, bonds, and commodities`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly and 1x per month`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $50,000`}</li>
      </ul>
      <p>{`TuringTrader’s All-Stars Stocks aims to continually outperform the S&P 500 at lower volatility and with significantly lower maximum drawdowns. The meta-portfolio achieves its objective by combining two high-powered momentum-based stock market strategies, resulting in additional diversification benefits. Further, All-Stars Stocks rebalances only five times per month, leading to moderate maintenance requirements. In summary, the portfolio appeals investors seeking aggressive growth.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Tax-Efficient: A tax-efficient meta-strategy",
                "title": "TuringTrader.com, All-Stars Tax-Efficient: A tax-efficient meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
        </ul>
      </Aside>
      <h3>{`Strategy Rules`}</h3>
      <p>{`The operation of `}<em parentName="p">{`All-Stars Stocks`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into two equal-sized tranches`}</li>
        <li parentName="ul">{`allocate one tranche each to TuringTrader's `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{` and `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a></li>
        <li parentName="ul">{`rebalance between tranches once per month`}</li>
      </ul>
      <p><em parentName="p">{`All-Stars Stocks`}</em>{` diversifies across investment methodologies by combining two strategies. Even though both component strategies are momentum-based, this reduces volatility and broadens the portfolio's scope. `}<em parentName="p">{`Round Robin`}</em>{`'s approach of investing in broader market indices greatly complements `}<em parentName="p">{`Stocks on the Loose`}</em>{`'s stock-picking approach. As a result, the meta-strategy achieves higher risk-adjusted returns than its components.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`In bullish periods, `}<em parentName="p">{`All-Stars Stocks`}</em>{` invests up to 100% of its capital in the U.S. Stock Market. The portfolio decreases its stock-market exposure and shifts toward a mix of bonds when market volatility increases. During bear markets and recessions, `}<em parentName="p">{`All-Stars Stocks`}</em>{` fully invests in assets with low correlation to the stock market. `}</p>
      <p><em parentName="p">{`All-Stars Stocks`}</em>{` achieves its diversification benefits primarily through serial diversification. As a consequence, the portfolio has a tail risk similar to holding the S&P 500. However, the portfolio's beta of approximately 0.3 shows that portfolio performance is well decoupled from the markets. Further, backtests show that the portfolio has successfully handled a wide range of historical market scenarios.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`All-Stars Stocks`}</em>{` beats the S&P 500 index in most years. Over the entire economic cycle, the portfolio delivers almost twice the return of its benchmark. Simultaneously, the portfolio shows lower measures in all risk metrics, including standard deviation of returns, absolute drawdown, and maximum flat days. For most metrics, All-Stars Stocks' risk profile is even lower than that of a passive 60/40 portfolio.`}</p>
      <p>{`With these properties, `}<em parentName="p">{`All-Stars Stocks`}</em>{` bridges the gap between aggressive returns and prudent risk management, making high returns available to more conventional investors.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`All-Stars Stocks`}</em>{` trades frequently and regularly triggers taxable events. Investors should expect almost all capital gains to be short-term. Therefore, the strategy works best in tax-deferred accounts.`}</p>
      <p>{`To allow for accurate position sizing, `}<em parentName="p">{`All-Stars Stocks`}</em>{` requires an account size of no less than $50,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, March 2021`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, April 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{` to its latest revision.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, October 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin"
          }}>{`Round Robin`}</a>{` to its latest revision for improved performance in bear markets.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      